.details__columns {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-top: 32px;

  @include breakpoint(until-desktop) {
    display: block;
  }
}

.details__column {
  &:first-child {
    flex: 1 1 auto;

    @include breakpoint(desktop) {
      max-width: calc(100% - 220px);
    }
  }

  &:last-child {
    flex: 1 0 auto;
    max-width: 220px;

    @include breakpoint(until-desktop) {
      width: 100%;
      max-width: none;
      margin: 64px 0;
      text-align: center;
    }
  }
}

.details__sequence {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 16px;
}

.details__aminoacid {
  border: 0;
  background: none;
  font-size: 32px;
  text-align: center;
  user-select: text;

  &:focus {
    outline: 0;
  }
}

.details__area {
  margin-top: 48px;
}

.details__chart {
  margin-top: 32px;
  overflow-x: auto;

  @media (max-width: 701px) {
    width: calc(100vw - 32px) !important;
  }
}

.details__actions {
  margin-top: 10px;
}

.details__side-title {
  &:not(:first-child) {
    margin-top: 32px;
  }
}

.details__side-link {
  display: block;
  padding-left: 0;

  &::before {
    display: none;
  }
}

.details__metrics {
  text-align: center;
}

.details__metric {
  &.MuiButton-root { // specificity
    display: inline-block;
    margin: 4px;
    text-transform: none;
  }
}
