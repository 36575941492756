.list__viewport {
  max-width: none !important;
}

.list__wrapper.MuiPaper-rounded {
  max-width: 100%;
  box-shadow: none;
}

.list__filter-wrapper {
  &.MuiGrid-item {
    display: flex;
    align-items: baseline;
    margin: 8px 0;
  }
}

.list__filter {
  &.MuiFormControl-root {
    margin: 1em 0;
    min-width: 100px;
  }
}

.list__filter-button-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.list__filter-button {
  margin: 0 4px;
  border: 2px solid transparent;
  background: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    border-color: rgba($color-brand, .35)
  }
}

.list__filter-button--checked {
  border-color: $color-brand;
  background-color: rgba($color-brand, .15);
}

.list__container {
  overflow-x: auto;
}

.list {
  &.MuiTable-root {
    border-collapse: separate;
    width: 100%;
  }

  .MuiTableRow-head {
    .list-header__cell {
      &:not(.list-header) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .MuiTableCell-root {
    min-width: 108px;
    max-width: 200px;
    word-break: break-word;
    line-height: 16px;

    &.center {
      text-align: center;
    }

    button {
      position: relative;
      width: 100%;
      padding: 16px 18px 16px 0;
      border: 0;
      background: none;
      text-align: left;
      cursor: pointer;

      &.download-button {
        width: 100px;
        padding: 0;
        color: $color-brand;
        font-weight: 700;

        &:hover,
        &:focus {
          text-decoration: underline;
        }

        .inline-icon {
          margin-right: 8px;
          margin-left: 0;
        }
      }
    }
  }
}

.list-header__cell {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
  background-color: #fafafa;

  &.larger {
    min-width: 118px;
  }

  &.x-larger {
    min-width: 200px;
  }
}

.list-header__sort-icon {
  position: absolute;
  right: 1px;
  width: 16px;
  height: 16px;
  visibility: hidden;

  .sorting--asc & {
    visibility: visible;
    transform: rotate(-90deg);
  }

  .sorting--desc & {
    visibility: visible;
    transform: rotate(90deg);
  }
}

.list__export-all-none {
  min-width: 96px;
}
