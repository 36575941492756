.manage-structure__pdb {
  .MuiDropzoneArea-root {
    min-height: 0;
  }

  .MuiDropzoneArea-text,
  .MuiDropzonePreviewList-root {
    display: none;
  }
}

.small {
  &.MuiTableCell-root {
    max-width: 80px;
  }
}

.manage-structures {
  width: 100%;

  form {
    width: 100%;
  }
}

.manage-structures__dnd-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
