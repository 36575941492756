.protein {
  &.Viewport__content {
    padding: 0;
    min-height: calc(100vh - 64px - 111px);
    justify-content: stretch;

    .collapsible__toggle {
      padding-left: 24px;
      padding-right: 42px;
    }

    .collapsible__content {
      display: flex;
      align-items: stretch;
      padding-left: 24px;
      padding-right: 24px;
    }

    .collapsible__toggle-icon {
      right: 25px;
    }
  }
}

.main-sequence {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
}

.protein-structure--apr {
  background-color: rgba($color-brand, .3);
}

.protein-structure--strct1 {
  background: $color-strct1;
  color: $color-white;
}

.protein-structure--strct2 {
  background: $color-strct2;
  color: $color-white;
}

.main-sequence__residue {
  position: relative;
  margin: 3px 0;
  padding: 8px 18px;
  border: 1px solid $color-grey1;
  font-size: 36px;
  width: 66px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  color: $color-black;

  .main-sequence__legend-item & {
    width: 28px;
    height: 28px;
    padding: 0;
    margin-right: 8px;
  }

  &.protein-structure--strct1 {
    border-top-color: $color-strct1;
    box-shadow: inset 0 3px $color-strct1;
  }

  &.protein-structure--strct2 {
    border-bottom-color: $color-strct2;
    box-shadow: inset 0 -3px $color-strct2;

    &.protein-structure--strct1 {
      box-shadow: inset 0 3px $color-strct1, inset 0 -3px $color-strct2;
    }
  }

  &.protein-structure--mutation {
    font-size: 18px;
  }

  .main-sequence__strct1-mutation {
    position: absolute;
    top: 4px;
    right: 6px;
    font-size: 28px;
    color: $color-strct1;
  }

  .main-sequence__strct2-mutation {
    position: absolute;
    bottom: 4px;
    left: 6px;
    font-size: 28px;
    color: $color-strct2;
  }

  .main-sequence__mutation--smaller {
    font-size: 22px;
    top: 2px;
    right: 2px;
  }

  &.protein-structure--apr {
    background-color: rgba($color-brand, .3);
  }
}

.main-sequence__legend-item {
  display: flex;
  align-items: center;
  margin: 16px 0;

  &.protein-structure--strct1 {
    background: $color-strct1;
    color: $color-white;
  }

  &.protein-structure--strct2 {
    background: $color-strct2;
    color: $color-white;
  }
}

.protein-selectors {
  display: flex;
  position: sticky;
  top: 64px;
  z-index: 500;
  width: 100%;
  background: $color-white;
  border-bottom: 2px solid $color-brand;
  padding: 16px 0;
}

.protein-structures__group-name {
  padding: 8px 0 16px;
  text-align: center;
}

.protein-structures {
  width: 50%;
}

.protein-structures__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.protein-structure {
  border: 0;
  background: none;
  width: 72px;
  padding: 4px 0;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;

  &.protein-structure--strct1 {
    background: $color-strct1;
    color: $color-white;
  }

  &.protein-structure--strct2 {
    background: $color-strct2;
    color: $color-white;
  }
}

.structure-models {
  width: 100%;
}

.structure-model {
  width: calc(50% - 16px);
  display: flex;
  flex-direction: column;
  margin: 0 8px;

  &:first-child {
    .ngl__wrapper {
      border: 3px solid $color-strct1;
      border-radius: 20px;
    }
  }

  &:last-child {
    .ngl__wrapper {
      border: 3px solid $color-strct2;
      border-radius: 20px;
    }
  }

  .download-button {
    display: block;
    margin: 24px auto;
    border: 0;
    background: none;
    padding: 0;
    color: $color-brand;
    font-weight: 700;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    .inline-icon {
      margin-right: 8px;
      margin-left: 0;
    }
  }
}

.download-listing-button {
  &.MuiButton-root {
    min-width: 140px;
  }

  .inline-icon {
    margin-right: 8px;
    margin-left: 0;
  }
}

.structure-info {
  flex: 1 1 auto;
  padding: 2px;
}

.dgscale__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.protein-dgscale {
  width: 100%;
  overflow-x: auto;
}

.protein__external-links {
  margin: 32px auto;
  text-align: center;
}

.protein__external-links-container {
  margin-top: 16px;

  a {
    margin: 0 16px;
  }
}

.dgscale__legend {
  display: flex;
  justify-content: center;
  margin: 8px 0 16px;
}

.dgscale__legend-item {
  display: flex;
  align-items: center;
  margin: 0 8px;
}

.dgscale__residue {
  width: 16px;
  height: 16px;
  margin: 0 8px;
}
