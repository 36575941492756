.protein-comparison {
  .protein.Viewport__content & {
    .collapsible__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.comparison-table {
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0 auto;

  td {
    padding: 0;
    font-size: 12px;
    text-align: center;

    &.comparison-header {
      padding: 2px 1px;
    }
  }

  .comparison-column--hover,
  .comparison-row--hover {
    &:not(.comparison-header--strct1):not(.comparison-header--strct2) {
      background-color: $color-grey1;
    }
  }
}

.comparison-header {
  font-weight: bold;

  &.comparison-header--strct1 {
    background-color: $color-strct1;
    color: $color-white;
  }

  &.comparison-header--strct2 {
    background-color: $color-strct2;
    color: $color-white;
  }
}

.comparison-marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 1px auto;
  background-color: $color-brand;
}

.comparison-label {
  border: 0;
  background: none;
  padding: 0;
  width: 100%;
  height: 20px;
  cursor: pointer;
}

.comparison-legend {
  display: flex;
  margin: 24px 0 48px;
  font-size: 12px;
}

.comparison-legend-block {
  width: 150px;
  background: linear-gradient(to right, rgba($color-brand, 0), rgba($color-brand, 1));
  margin: 0 16px;
  border-radius: 16px;
}
